<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-alt"></b-icon>
              <span> {{ $t('generate_report') }} </span>
            </template>
            <div>
              <FormGenerateReport ref="formGenerateReport"
                :type-report="typeReport"
                @refresh-history="onRefreshHistory"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="history"></b-icon>
              <span> {{ $t('report_history') }} </span>
            </template>
            <div>
              <HistoryReports ref="historyReports"
                :project-id="project.id"
                @load-report="onLoadReport"/>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import FormGenerateReport from './dashboards/components/form-generate-report.vue';
import HistoryReports from './dashboards/components/history-reports.vue';

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    FormGenerateReport,
    HistoryReports,
  },

  computed: {
    ...mapGetters({
      navData: 'app/navData',
      project: 'app/project',
    }),
  },

  data: () => ({
    project_id: null,
    typeReport: null,
    mainActiveTab: 0
  }),

  created() {
    // this.$options.subtitle = ['project_actions_page_subtitle', [this.navData.project_name.toUpperCase()]]
    this.typeReport = typeof this.$route.query.type === 'undefined' ? null : this.$route.query.type
    this.project_id = this.project.id
  },

  methods: {
    onRefreshHistory() {
      this.$refs.historyReports.reload()
    },

    onLoadReport(report) {
      this.mainActiveTab = 0;
      this.$refs.formGenerateReport.LoadReport(report)
    }
  },
};

</script>

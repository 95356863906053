import {DxfFetcher} from "./DxfFetcher"
import opentype from "opentype.js"

import {KonvaScene} from "../../viewer-konva/utils/index"

const MSG_SIGNATURE = "DxfWorkerMsg"

/** Wraps web-worker instance and provides unified interface to its services, including the when
 * web-worker is not used and all heavy operations are performed in main thread.
 */
export class DxfWorker {
    /** @param worker Web worker instance with DxfViewer.SetupWorker() function called. Can be null
     *  for synchronous operations.
     *  @param isWorker True for worker-side wrapper.
     */
    constructor(worker, isWorker = false) {
        this.worker = worker
        if (isWorker) {
            worker.onmessage = this._ProcessRequest.bind(this)
        } else if (worker) {
            worker.addEventListener("message", this._ProcessResponse.bind(this), false)
            worker.addEventListener("error", this._OnError.bind(this), false)
            this.reqSeq = 1
            /* Indexed by sequence. */
            this.requests = new Map()
            this.progressCbk = null
        }
    }

    async CreateEntities(dxf, layers, cadLayeredDesign){
      return await this._CreateEntities(dxf, layers, cadLayeredDesign)
    }


    async CreateObject(dxf){
      const konvaScene = new KonvaScene()
      await konvaScene.Build(dxf)
      return konvaScene.scene
    }


    async Destroy(noWait = false) {
        if (this.worker) {
            if (!noWait) {
                await this._SendRequest(DxfWorker.WorkerMsg.DESTROY)
            }
            /* close() in the worker is not enough, instance is still visible in dev tools. */
            this.worker.terminate()
        }
    }

    async _ProcessRequest(event) {
        const msg = event.data
        if (msg.signature !== MSG_SIGNATURE) {
            console.log("Message with bad signature", msg)
            return
        }
        const resp = {seq: msg.seq, type: msg.type, signature: MSG_SIGNATURE}
        const transfers = []
        try {
            resp.data = await this._ProcessRequestMessage(msg.type, msg.data, transfers, msg.seq)
        } catch (error) {
            console.error(error)
            resp.error = String(error)
        }
        this.worker.postMessage(resp, transfers)
        if (msg.type === DxfWorker.WorkerMsg.DESTROY) {
            this.worker.onmessage = null
            this.worker.close()
            this.worker = null
        }
    }

    async _ProcessRequestMessage(type, data, transfers, seq) {
        switch (type) {
        case DxfWorker.WorkerMsg.LOAD: {
            const {scene, dxf} = await this._Load(
                data.url,
                data.fonts,
                data.options,
                (phase, size, totalSize) => this._SendProgress(seq, phase, size, totalSize))
            transfers.push(scene.vertices)
            transfers.push(scene.indices)
            transfers.push(scene.transforms)
            return {scene, dxf}
        }
        case DxfWorker.WorkerMsg.DESTROY:
            return null
        default:
            throw "Unknown message type: " + type
        }
    }

    async _ProcessResponse(event) {
        const msg = event.data
        if (msg.signature !== MSG_SIGNATURE) {
            console.log("Message with bad signature", msg)
            return
        }
        const seq = msg.seq
        const req = this.requests.get(seq)
        if (!req) {
            console.error("Unmatched message sequence: ", seq)
            return
        }
        const data = msg.data
        if (msg.type === DxfWorker.WorkerMsg.PROGRESS && req.progressCbk) {
            req.progressCbk(data.phase, data.size, data.totalSize)
            return
        }
        this.requests.delete(seq)
        if (msg.hasOwnProperty("error")) {
            req.SetError(msg.error)
        } else {
            req.SetResponse(data)
        }
    }

    async _OnError(error) {
        console.error("DxfWorker worker error", error)
        const reqs = Array.from(this.requests.values)
        this.requests.clear()
        reqs.forEach(req => req.SetError(error))
    }

    async _SendRequest(type, data = null, progressCbk = null) {
        const seq = this.reqSeq++
        const req = new DxfWorker.Request(seq, progressCbk)
        this.requests.set(seq, req)
        this.worker.postMessage({ seq, type, data, signature: MSG_SIGNATURE})
        return await req.GetResponse()
    }

    _SendProgress(seq, phase, size, totalSize) {
        this.worker.postMessage({
            seq,
            type: DxfWorker.WorkerMsg.PROGRESS,
            data: {phase, size, totalSize},
            signature: MSG_SIGNATURE
        })
    }

    // Method que permite cargar el dxf y parsearlo a json
    async _ConvertDxfToJson(url, fileEncoding, progressCbk) {
      // dxf = contiene el dxf file parseado a json
      const dxf = await new DxfFetcher(url, fileEncoding).Fetch(progressCbk)
      if (progressCbk) {
          progressCbk("prepare", 0, null)
      }

      // console.log( 'dxf: ', dxf )
      return { dxf: dxf }
    }

    async _CreateEntities(dxf, layers, cadLayeredDesign) {

      let clonedEntities = []

      let totalDesign = {}

      // console.log('dxf: ', dxf)

      // solo se considera las entidades de tipo poliline
      for (const entity of this._GetEntitiesDxf(dxf)) {
        // console.log('entity: ', entity)
        if (entity.type !== 'POLYLINE' && entity.type !== 'LWPOLYLINE' ) {
          continue
        }

        // iteramos sobre los layer para crear las entidades solo aquellas que tienen la propiedad create_entities a true y no
        // esten en el diseño
        for (const layer of layers) {
          if( layer.create_entities && !layer.in_design ) {
            // Aquí obtenemos antes la cantidad de divisiones a generar
            let quantity = this._GetQuantity(entity, layer, cadLayeredDesign)
            let numAxles = this._GetQuantityAxles(entity, layer, cadLayeredDesign)
            // determinar el sentido en el que van los puntos de un polígono en el caso de de ser negativo lo corregimos
            // this._CorregirOrientacionPoligono(entity)

            this._ProcessDxfEntity(dxf, entity, quantity, layer.code.toUpperCase(), numAxles)
            .forEach(element => {
              clonedEntities.push(element)
            });

            let totPanelsDesign = this._GetQuantity(entity, { code: 'panels' }, cadLayeredDesign)

            if (totalDesign[layer.code]) {
              switch (layer.code) {
                case 'pilings':
                  totalDesign[layer.code] += quantity * numAxles
                  break;
                case 'modules':
                  totalDesign[layer.code] += quantity * totPanelsDesign
                  break;

                default:
                  totalDesign[layer.code] += quantity
                  break;
              }
            }
            else {
              switch (layer.code) {
                case 'pilings':
                  totalDesign[layer.code] = quantity * numAxles
                  break;
                case 'modules':
                  totalDesign[layer.code] = quantity * totPanelsDesign
                  break;

                default:
                  totalDesign[layer.code] = quantity
                  break;
              }
            }

            // TODO: aquí creamos un hack para sumar los panels por modulo
            if (layer.code == 'modules') {
              if (totalDesign['panels'])
                totalDesign['panels'] += quantity * totPanelsDesign
              else
                totalDesign['panels'] = quantity * totPanelsDesign
            }
          }
        }
      }

      clonedEntities.forEach(element => {
        dxf.entities.push( element )
      });

      return totalDesign

    }

    _CorregirOrientacionPoligono(entity) {
        let suma = 0;

        let totVertices = entity.vertices.length <= 4 ? entity.vertices.length : entity.vertices.length - 1

        for (let i = 0; i < totVertices; i++) {
            let puntoActual = entity.vertices[i];
            let puntoSiguiente = entity.vertices[(i + 1) % totVertices];

            // suma += (puntoSiguiente[0].x - puntoActual[0].x) * (puntoSiguiente[1].x + puntoActual[1].x);
            suma += (puntoActual.x * puntoSiguiente.y ) - (puntoSiguiente.x * puntoActual.y);
        }
        if (suma > 0) {
            console.log("positiva"); // Sentido horario
        } else if (suma < 0) {
            console.log("negativa"); // Sentido antihorario
        } else {
            console.log("colineal"); // Los puntos son colineales (forman una línea recta)
        }
    }

    _GetQuantity(entity, lyr, params) {
      let quantity = params.default['total_' + lyr.code]

      if( params.hasOwnProperty(entity.layer.replace(/\s/g, '_')) ) {
        quantity = params[entity.layer.replace(/\s/g, '_')]['total_' + lyr.code]
      }

      return quantity
    }

    _GetQuantityAxles(entity, lyr, params) {
      let quantity = params.default['total_axles']

      if( params.hasOwnProperty(entity.layer.replace(/\s/g, '_')) ) {
        quantity = params[entity.layer.replace(/\s/g, '_')]['total_axles']
      }

      return quantity
    }

    *_GetEntitiesDxf(dxf) {
      for (const entity of dxf.entities)
        yield entity
    }

    // Procesamos la entidad para dividirla en sub entities
    /**
     *
     * @param {*} dxf
     * @param {*} entity
     * @param {*} numDivisions
     * @param {*} type
     * @param {*} numberAxles = "Numero de ejes que contiene la estrucutura por defecto es una"
     * @returns
     */
    _ProcessDxfEntity(dxf, entity, numDivisions, type, numberAxles=1) {

      // aplicamos un fix para verificar que el primer segmento sea horizontal si es vertical modificar los puntos
      if (this._DeterminateOrientation(entity.vertices[0], entity.vertices[1]) == 'v') {
        entity.vertices = this._OrdenarVertices(entity)
        // let newVertices = []
        // newVertices.push(entity.vertices[3])
        // newVertices.push(entity.vertices[0])
        // newVertices.push(entity.vertices[1])
        // newVertices.push(entity.vertices[2])
        // entity.vertices = newVertices

      }

      let clonedEntities = []
      //obtenes el nombre del layer que contendra las sub entidades
      let nameLayer = this._CreateLayer( dxf, entity.layer, type )

      // Es una estructura de mas de un eje
      if ( this._IsMultiAxlsSpecialType(numberAxles, type) ) {
        let axlesEntities = this._ProcessCalculateAxles(dxf, entity, numberAxles)

        axlesEntities.forEach(element => {
          clonedEntities.push(element)
          clonedEntities = [ ...clonedEntities, ...this._DividerEntity(element, nameLayer, numDivisions)]
        });

        return clonedEntities

      } else {
        return this._DividerEntity(entity, nameLayer, numDivisions)
      }
    }

    _IsMultiAxlsSpecialType(numberAxles, type) {
      const validTypes = ['pilings', 'modules'];
      return numberAxles > 1 && validTypes.includes(type.toLowerCase());
    }

    /**
     * Methodo determina sergmentos mas largos y manda a divider la entidad pasada
     */
    _DividerEntity(entity, nameLayer, numDivisions) {
      // Buscamos los segmentos mas largos para ser divididos en partes iguales
      let longerSegments = this._FindSegments(entity.vertices, 'longer') // deveulve longitud y en que vertices esta comprendido
      // Ordenamos de segun indice del segmento de menor a mayor para evitar que se altere la grafica,
      // para evitar intercambio de longitud de segmento
      longerSegments.sort((a, b) => a.pos - b.pos);

      let posVertex = [longerSegments[0].pos, longerSegments[1].pos]
      let orientation = longerSegments[0].orientation

      // Ordenamos los vertices
      let vertexSegment = []
      vertexSegment.push(longerSegments[0].vertex1)
      vertexSegment.push(longerSegments[0].vertex2)
      vertexSegment.push(longerSegments[1].vertex1)
      vertexSegment.push(longerSegments[1].vertex2)

      // procesamos la entidad realizando las divisiones
      return this._HorizontalCloningEntity(entity, nameLayer, numDivisions, vertexSegment, posVertex)
    }

    _ProcessCalculateAxles(dxf, entity, numDivisions) {
      // Buscamos los segmentos mas cortos para ser divididos en partes iguales
      let shortestSegments = this._FindSegments(entity.vertices, 'shortest') // deveulve longitud y en que vertices esta comprendido
      // Ordenamos segun indice del segmento de menor a mayor para evitar que se altere la grafica,
      // para evitar intercambio de longitud de segmento
      shortestSegments.sort((a, b) => a.pos - b.pos);
      let posVertex = [shortestSegments[0].pos, shortestSegments[1].pos]
      let orientation = shortestSegments[0].orientation
      // Ordenamos los vertices
      let vertexSegment = []
      vertexSegment.push(shortestSegments[0].vertex1)
      vertexSegment.push(shortestSegments[0].vertex2)
      vertexSegment.push(shortestSegments[1].vertex1)
      vertexSegment.push(shortestSegments[1].vertex2)

      //obtenes el nombre del layer que contendra las sub entidades
      let layerName = this._CreateLayer( dxf, entity.layer, 'AXLES' )

      let clonedEntities = this._VerticalCloningEntity(entity, layerName, numDivisions, vertexSegment, posVertex)

      return clonedEntities

    }

    _VerticalCloningEntity(entity, layerName, numDivisions, vertexSegment, orientation) {
      // procesamos la entidad realizando las divisiones
      let clonedEntities = []
      for (let index = 0; index < numDivisions; index++) {
        let indexPrev = index - 1;

        let newVertices = this._CalculateVertices(index + 1, numDivisions, vertexSegment)

        if( clonedEntities.length == 0 ) {
          clonedEntities.push(JSON.parse(JSON.stringify(entity)))
          clonedEntities[index].layer = layerName
          clonedEntities[index].parentStructureId = entity.handle  // para identificar a que strucutra esta asociado biene del cad
          // seteamos el layer al nombre que corresponde para no tener error (si no es necesario eliminar)
          for (let iv = 0; iv < clonedEntities[index].vertices.length; iv++) {
            clonedEntities[index].vertices[iv].layer = layerName;
          }
          // si no existe newVertice es por que solo se esta clonando el elemeto una vez no se esta dividiendo
          if( newVertices != null ) {
            // if (orientation == 'v') {
            if (orientation[0] == 2) {
              clonedEntities[index].vertices[2].x = newVertices.v1.x
              clonedEntities[index].vertices[2].y = newVertices.v1.y

              clonedEntities[index].vertices[3].x = newVertices.v2.x
              clonedEntities[index].vertices[3].y = newVertices.v2.y
            } else {
              clonedEntities[index].vertices[1].x = newVertices.v1.x
              clonedEntities[index].vertices[1].y = newVertices.v1.y

              clonedEntities[index].vertices[2].x = newVertices.v2.x
              clonedEntities[index].vertices[2].y = newVertices.v2.y
            }
          }
        }
        else {
          clonedEntities.push(JSON.parse(JSON.stringify(clonedEntities[indexPrev])))

          // if (orientation == 'v') { // vertical
          if (orientation[0] == 2) { // vertical
            clonedEntities[index].vertices[0].x = clonedEntities[index].vertices[3].x
            clonedEntities[index].vertices[0].y = clonedEntities[index].vertices[3].y

            clonedEntities[index].vertices[1].x = clonedEntities[index].vertices[2].x
            clonedEntities[index].vertices[1].y = clonedEntities[index].vertices[2].y

            clonedEntities[index].vertices[2].x = newVertices ? newVertices.v1.x : vertexSegment[1].x
            clonedEntities[index].vertices[2].y = newVertices ? newVertices.v1.y : vertexSegment[1].y

            clonedEntities[index].vertices[3].x = newVertices ? newVertices.v2.x : vertexSegment[2].x
            clonedEntities[index].vertices[3].y = newVertices ? newVertices.v2.y : vertexSegment[2].y
          } else { // horizontal
            clonedEntities[index].vertices[0].x = clonedEntities[index].vertices[1].x
            clonedEntities[index].vertices[0].y = clonedEntities[index].vertices[1].y

            clonedEntities[index].vertices[3].x = clonedEntities[index].vertices[2].x
            clonedEntities[index].vertices[3].y = clonedEntities[index].vertices[2].y

            clonedEntities[index].vertices[1].x = newVertices ? newVertices.v1.x : vertexSegment[1].x
            clonedEntities[index].vertices[1].y = newVertices ? newVertices.v1.y : vertexSegment[1].y

            clonedEntities[index].vertices[2].x = newVertices ? newVertices.v2.x : vertexSegment[2].x
            clonedEntities[index].vertices[2].y = newVertices ? newVertices.v2.y : vertexSegment[2].y
          }
        }
      }

      return clonedEntities
    }

    _HorizontalCloningEntity(entity, layerName, numDivisions, vertexSegment, orientation) {
      // procesamos la entidad realizando las divisiones
      let clonedEntities = []

      for (let index = 0; index < numDivisions; index++) {
        let indexPrev = index - 1;

        let newVertices = this._CalculateVertices(index + 1, numDivisions, vertexSegment)

        if( clonedEntities.length == 0 ) { // primera clonacion
          clonedEntities.push(JSON.parse(JSON.stringify(entity)))
          clonedEntities[index].layer = layerName
          clonedEntities[index].parentStructureId = entity.handle  // para identificar a que strucutra esta asociado
          for (let iv = 0; iv < clonedEntities[index].vertices.length; iv++) {
            clonedEntities[index].vertices[iv].layer = layerName;
          }

          // si no existe newVertice es por que solo se esta clonando el elemeto una vez no se esta dividiendo
          if (newVertices != null) {
            // post vertex ayuda a identificar si la estrucutra esta de manera vertical u horizontal
            // if (orientation == 'h') { // horizontal
            if (orientation[0] == 1) { // horizontal
              clonedEntities[index].vertices[1].x = newVertices.v1.x
              clonedEntities[index].vertices[1].y = newVertices.v1.y

              clonedEntities[index].vertices[2].x = newVertices.v2.x
              clonedEntities[index].vertices[2].y = newVertices.v2.y
            } else { // vertical

              clonedEntities[index].vertices[2].x = newVertices.v1.x
              clonedEntities[index].vertices[2].y = newVertices.v1.y

              clonedEntities[index].vertices[3].x = newVertices.v2.x
              clonedEntities[index].vertices[3].y = newVertices.v2.y
            }
          }
        }
        else {
          clonedEntities.push(JSON.parse(JSON.stringify(clonedEntities[indexPrev])))

          // if (orientation == 'h') { // hotizontal
          if (orientation[0] == 1) { // hotizontal
            clonedEntities[index].vertices[0].x = clonedEntities[index].vertices[1].x
            clonedEntities[index].vertices[0].y = clonedEntities[index].vertices[1].y

            clonedEntities[index].vertices[3].x = clonedEntities[index].vertices[2].x
            clonedEntities[index].vertices[3].y = clonedEntities[index].vertices[2].y

            clonedEntities[index].vertices[1].x = newVertices ? newVertices.v1.x : vertexSegment[1].x
            clonedEntities[index].vertices[1].y = newVertices ? newVertices.v1.y : vertexSegment[1].y

            clonedEntities[index].vertices[2].x = newVertices ? newVertices.v2.x : vertexSegment[2].x
            clonedEntities[index].vertices[2].y = newVertices ? newVertices.v2.y : vertexSegment[2].y
          } else { // vertcal
            clonedEntities[index].vertices[0].x = clonedEntities[index].vertices[3].x
            clonedEntities[index].vertices[0].y = clonedEntities[index].vertices[3].y

            clonedEntities[index].vertices[1].x = clonedEntities[index].vertices[2].x
            clonedEntities[index].vertices[1].y = clonedEntities[index].vertices[2].y

            clonedEntities[index].vertices[2].x = newVertices ? newVertices.v1.x : vertexSegment[1].x
            clonedEntities[index].vertices[2].y = newVertices ? newVertices.v1.y : vertexSegment[1].y

            clonedEntities[index].vertices[3].x = newVertices ? newVertices.v2.x : vertexSegment[2].x
            clonedEntities[index].vertices[3].y = newVertices ? newVertices.v2.y : vertexSegment[2].y
          }
        }
      }

      return clonedEntities
    }

    _CalculateVertices(r, numDivisions, vertices) {

      if  ( numDivisions - r > 0 ) {
        const v1 = { x: 0, y: 0 }
        const v2 = { x: 0, y: 0 }

        r = r / (numDivisions - r)

        v1.x = (vertices[0].x + (r * vertices[1].x))/(1+r)
        v1.y = (vertices[0].y + (r * vertices[1].y))/(1+r)

        v2.x = (vertices[3].x + (r * vertices[2].x))/(1+r)
        v2.y = (vertices[3].y + (r * vertices[2].y))/(1+r)

        return { v1, v2 }
      }

      return null
    }

    _FindSegments(vertices, type='longer') {

      const lados = [];

      // LWPOLYLINE tiene un quinto vertice y lo tratamos aquí, solo consideramos polilineas de 4 vertices
      // el quinto vertices es el mismo que el primer vertices
      let totalVertices = vertices.length <= 4 ? vertices.length : vertices.length - 1

      // Calcular la longitud de cada lado
      for (let i = 0; i < totalVertices; i++) {
        const vertex1 = vertices[i];
        const pos = i + 1; // identifica indice del segmento (entre que vertices esta el segmento)
        const vertex2 = vertices[(i + 1) % totalVertices]; // Conectar el último punto al primero
        const distancia = this._CalculateSegmentLength(vertex1, vertex2);
        const orientation = this._DeterminateOrientation(vertex1, vertex2)

        lados.push({ vertex1, vertex2, pos, distancia, orientation });
      }

      if( type === 'longer' ) {
        // Ordenar los lados por longitud de manera descendente segmentos mas largos (longer)
        lados.sort((a, b) => b.distancia - a.distancia);
      } else {
        // Ordenar los lados por longitud de manera acendente segmento mas cortos (shortest)
        lados.sort((a, b) => a.distancia - b.distancia);
      }

      // Obtener los 4 lados más largos (o menos si el polígono tiene menos de 4 lados)
      return lados.slice(0, Math.min(2, lados.length));
    }

    _CalculateSegmentLength(v0, v1) {
      // let lonsegment = Math.sqrt(Math.pow(ver1.x - ver0.x, 2) + Math.pow(ver1.y - ver0.y, 2))

      const dx = v1.x - v0.x;
      const dy = v1.y - v0.y;
      return Math.sqrt(dx * dx + dy * dy);

      // return Math.sqrt(Math.pow(v1.x - v0.x, 2) + Math.pow(v1.y - v0.y, 2))
    }

    _DeterminateOrientation(v0, v1) {
      if(v0.x == v1.x)
        return 'v'

      return 'h'
    }

    _OrdenarVertices(entity) {

      // let newVertices = []
      // newVertices.push(entity.vertices[3])
      // newVertices.push(entity.vertices[0])
      // newVertices.push(entity.vertices[1])
      // newVertices.push(entity.vertices[2])
      // entity.vertices = newVertices

     let poligono = entity.vertices
      // Encontrar el vértice con la coordenada x más baja
      const primerVertice = poligono.reduce((minVertice, actualVertice, indice) => {
        return actualVertice.x < minVertice.x && actualVertice.y >= minVertice.y ? actualVertice : minVertice
      });

      // Calcula el ángulo polar de cada vértice con respecto al vértice inicial
      const verticesConAngulos = poligono.map(punto => {
        const angulo = Math.atan2(punto.y - primerVertice.y, punto.x - primerVertice.x);
        return { punto, angulo };
      });

      // Ordena los vértices según el ángulo polar
      const verticesOrdenados = verticesConAngulos.sort((a, b) => a.angulo - b.angulo);

      // Extrae los vértices ordenados
      const poligonoOrdenado = verticesOrdenados.map(item => item.punto);

      //   // Calcular el ángulo polar para cada vértice con respecto al primer vértice
      //   const angulosPolares = poligono.map(vertice =>
      //     Math.atan2(vertice.y - primerVertice.y, vertice.x - primerVertice.x)
      //   );

      //   // Ordenar los vértices según los ángulos polares en sentido horario
      //   const indicesOrdenados = angulosPolares
      //     .map((angulo, indice) => ({ indice, angulo }))
      //     .sort((a, b) => a.angulo - b.angulo)
      //     .map(vertice => vertice.indice);

      //   // Crear el polígono ordenado
      //   const poligonoOrdenado = indicesOrdenados.map(indice => poligono[indice]);

      let newVertices = []
      newVertices.push(poligonoOrdenado[3])
      newVertices.push(poligonoOrdenado[2])
      newVertices.push(poligonoOrdenado[1])
      newVertices.push(poligonoOrdenado[0])

      return newVertices;
    }

    // TODO: optimizar para considerar las carateristicas d
    _CreateLayer(dxf, nameLayer, type) {
      let layerEntity = null // layer en la que se encuentra la entidad
      for (const [, layer] of Object.entries(dxf.tables.layer.layers)) {
        if (nameLayer == layer.name)
          layerEntity = layer
      }

      if (layerEntity) {
        nameLayer = type + '_EPC_' + nameLayer
        // TODO: FALTA UN IF PATA VALIDAR SI EXISTE O NO
        // if( dxf.tables.layer.layers[nameLayer] )
        dxf.tables.layer.layers[nameLayer] = {
          color: layerEntity.color,
          colorIndex: layerEntity.colorIndex,
          displayName: nameLayer,
          frozen: layerEntity.frozen,
          name: nameLayer,
          visible: true
        }

      }

      return nameLayer
    }

    _CreateFontFetchers(urls, progressCbk) {

        function CreateFetcher(url) {
            return async function() {
                if (progressCbk) {
                    progressCbk("font", 0, null)
                }
                const data = await fetch(url).then(response => response.arrayBuffer())
                if (progressCbk) {
                    progressCbk("prepare", 0, null)
                }
                return opentype.parse(data)
            }
        }

        const fetchers = []
        for (const url of urls) {
            fetchers.push(CreateFetcher(url))
        }
        return fetchers
    }

    _CloneOptions(options) {
        /* Default options values are taken from prototype so need to implement deep clone here. */
        if (Array.isArray(options)) {
            return options.map(o => this._CloneOptions(o))
        } else if (typeof options === "object" && options !== null) {
            const result = {}
            for (const propName in options) {
                // noinspection JSUnfilteredForInLoop
                result[propName] = this._CloneOptions(options[propName])
            }
            return result
        } else {
            return options
        }
    }

    async FlattenScene(scene) {
      let entities = []
      for (const entity of this._GetEntitiesScene(scene)) {
        entities.push( JSON.stringify(entity) )
      }

      return entities
    }

    *_GetEntitiesScene(scene) {
      for (const entity of scene.entities) {
        yield entity
      }
    }
}

DxfWorker.WorkerMsg = {
    LOAD: "LOAD",
    PROGRESS: "PROGRESS",
    DESTROY: "DESTROY"
}

DxfWorker.Request = class {
    constructor(seq, progressCbk) {
        this.seq = seq
        this.progressCbk = progressCbk
        this.promise = new Promise((resolve, reject) => {
            this._Resolve = resolve
            this._Reject = reject
        })
    }

    async GetResponse() {
        return await this.promise
    }

    SetResponse(response) {
        this._Resolve(response)
    }

    SetError(error) {
        this._Reject(error)
    }
}
